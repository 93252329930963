import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import './button.css';

import bg from '../assets/MktDoFuturo_Site-03.png';
import logo from '../assets/MktDoFuturo_IDVisual-07.png';
import { HashLink } from 'react-router-hash-link';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <header className="bg-cover bg-center py-5 text-left" style={{ backgroundImage: `url(${bg})` }}>
        <nav className="flex justify-between items-center mx-5">
          <h1 className="uppercase font-semibold text-2xl text-white">
            <span className='text-4xl'>22 e 23 de julho</span><br />em recife - pe | 8h às 18h
          </h1>
          {/* <h2 className="uppercase font-semibold text-3xl text-white"></h2> */}
          <Icon
            icon="game-icons:hamburger-menu"
            color="white"
            width={40}
            height={40}
            onClick={() => setIsOpen(!isOpen)}
          />
        </nav>
      </header>
      <div className={`slide-container ${isOpen ? 'slide-down' : 'slide-up'} bg-white relative text-center px-5`}>
        <button type='button' onClick={() => setIsOpen(false)} className='font-black text-2xl absolute top-2 right-4'>X</button>
        <div className='mx-5 mb-10 mt-10'>
          <img src={logo} alt="Logo da Imersão Mkt do Futuro" className='sm:w-1/3 mx-auto object-contain' />
        </div>
        <HashLink smooth to='/home#palestrantes'>
          <h2 role='button' className='cursor-pointer hover:bg-black hover:text-white transition-all ease-in-out my-2 uppercase text-xl/none font-black border-3 border-black text-black text-center p-2'>palestrantes</h2>
        </HashLink>
        <HashLink smooth to='/home#responsabilidade'>
          <h2 role='button' className='cursor-pointer hover:bg-black hover:text-white transition-all ease-in-out my-2 uppercase text-xl/none font-black border-3 border-black text-black text-center p-2'>responsabilidade social e diversidade</h2>
        </HashLink>
        <HashLink smooth to='/home#talks'>
          <h2 role='button' className='cursor-pointer hover:bg-black hover:text-white transition-all ease-in-out mt-2 mb-6 uppercase text-xl/none font-black border-3 border-black text-black text-center p-2'>workshops</h2>
        </HashLink>
        <HashLink smooth to='/home#planos'>
          <button
            type='button'
            className='hover:text-2xl transition-all ease-in-out bg-black text-white my-5 text-center text-xl uppercase font-black w-fit cursor-pointer diagonal2'
          >
            garanta sua vaga
          </button>
        </HashLink>
      </div>
    </>
  );
}

export default Header;
