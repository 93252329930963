import React from "react";

function MapMobile() {
  return (
    <>
      <iframe title="mapcds" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.4030838444087!2d-34.872187985220144!3d-8.06030209419637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab18a24fe6ec27%3A0xbf67f7d56acfbd5d!2sCentro%20Cultural%20Cais%20do%20Sert%C3%A3o!5e0!3m2!1spt-BR!2sbr!4v1686168886875!5m2!1spt-BR!2sbr" width="300" height="400" style={{border: 0}} allowFullScreen="" loading="eager" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </>
  )
}

export default MapMobile;
