import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Programacao from './pages/Programacao';
import Streaming from './pages/Streaming';
import ErrorPage from './components/ErrorPage';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// import Fila from './pages/LoadingFila';

const router = createBrowserRouter([
  /* {
    path: "/",
    element: <Fila />,
    errorElement: <ErrorPage />,
  }, */
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/programacao",
    element: <Programacao />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/transmissao",
    element: <Streaming />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
