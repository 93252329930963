import React, {useState} from "react";
import logo from '../assets/04- Thanks-03.png';
// import icone from '../assets/spinner.svg';
import astro from '../assets/pngegg (1).png';
import planeta from '../assets/Planet-PNG-Photo-Image.png';


const arenaBusiness = <iframe width="560" height="315" src="https://www.youtube.com/embed/sRhP55MmvR4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>;

const arenaCreator = <iframe width="560" height="315" src="https://www.youtube.com/embed/gUtivbuPpho" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>;

const palcoTomorrow = <iframe width="560" height="315" src="https://www.youtube.com/embed/Z-ues4k0SSs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>;

function Main() {
  const [currentTransmission, setCurrentTransmission] = useState(arenaBusiness);

  const handleArenaBusinessClick = () => {
    setCurrentTransmission(arenaBusiness);
  };

  const handleArenaCreatorClick = () => {
    setCurrentTransmission(arenaCreator);
  };

  const handlePalcoTomorrowClick = () => {
    setCurrentTransmission(palcoTomorrow);
  };
  return (
    <div className="flex flex-col justify-center items-center p-20 h-full min-h-screen stream-container overflow-x-hidden">
      <div className="absolute -top-32 -left-48 w-1/3 max-sm:hidden">
        <img src={planeta} alt="imagem decorativa" />
      </div>
      <div className="absolute -bottom-14 -right-20 max-sm:hidden">
        <img src={astro} alt="imagem decorativa" />
      </div>
      <div className="w-1/4 mx-auto flex flex-col justify-center items-center max-md:w-3/4">
        <img src={logo} alt="logo do IMA" className="object-contain p-2" />
        <div className="ml-6 mt-2 mb-10 flex">
          <h3 className="uppercase text-5xl font-black">transmissão</h3>
          <p className="uppercase text-xs font-medium text-right pr-2 my-0.5 -rotate-90">online</p>
        </div>
      </div>
      <div className="border border-white h-[480px] w-[720px] max-md:w-[360px] max-md:h-[240px] text-center text-3xl uppercase font-bold flex flex-col items-center justify-center">
        {currentTransmission}
      </div>
      <div className="flex gap-4 mt-4 justify-evenly">
        <button className="bg-white text-black p-3 font-bold uppercase flex-1" onClick={handleArenaBusinessClick}>Arena Business</button>
        <button className="bg-white text-black p-3 font-bold uppercase flex-1" onClick={handleArenaCreatorClick}>Arena Creator</button>
        <button className="bg-white text-black p-3 font-bold uppercase flex-1" onClick={handlePalcoTomorrowClick}>Palco Tomorrow</button>
      </div>
    </div>
  )  
}

export default Main;
