import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/MktDoFuturo_IDVisual-07.png'

function ErrorPage() {
  return (
    <div className="bg-gray-100 h-screen text-center flex flex-col items-center justify-center py-10">
      <img src={logo} alt="Logo da Imersão MKT do Amanhã" className="w-1/2" />
      <h1 className="uppercase p-10 text-center text-4xl font-semibold">página não encontrada!</h1>
      <Link to="/" className="bg-black text-white p-2 uppercase text-lg font-semibold hover:font-bold hover:p-3 transition-all ease-linear">Home</Link>
    </div>
  )
}

export default ErrorPage;
