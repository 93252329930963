import React from 'react';
import { Icon } from '@iconify/react';
import * as Tabs from '@radix-ui/react-tabs';
import './styles.css';

const Tabcard = () => (
  <Tabs.Root className="TabsRoot" defaultValue="tab1">
    <Tabs.List className="TabsList" aria-label="Programação do evento">
      <Tabs.Trigger className="TabsTrigger text-2xl/none font-extrabold" value="tab1">
        DAY 01<span className='-rotate-90 text-xs/none font-bold'>SAB</span>
      </Tabs.Trigger>
      <Tabs.Trigger className="TabsTrigger text-2xl/none font-extrabold" value="tab2">
        DAY 02<span className='-rotate-90 text-xs/none font-bold'>DOM</span>
      </Tabs.Trigger>
    </Tabs.List>
    <Tabs.Content className="TabsContent" value="tab1">
      <ul className='list-disc pl-5'>
        <li className="uppercase "><strong className='font-black'>Potencialize sua produtividade com IA</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>9h</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>anselmo albuquerque</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Relações sociais e gestão de comunidade em Tempos de AI</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>9h50</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>maíra fischer</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Chat GPT e WhatsApp: O que podemos esperar dessa relação?</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>10h40</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>joão miguel salgues</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Technical Writing e AI: Não é mais uma página em branco</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>11h30</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>bruna sarga, ligia sarmento e felipe mozart</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>como o google pode potencializar o seu negócio usando IA</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>14h</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>analu gama</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>IA Generativa: Muito Além do Chat GPT e Bard</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>14h50</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>bruno moura</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>GA4 desafios e como usar seu potencial</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>15h40</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>gabrielle araújo</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Como extrair insights de dados para melhorar produtos e comunicação</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>16h30</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>edinaldo junior</p>
        </div>
        <br />
        <li className="uppercase ">17:20 <strong className='font-black'>a importância das lideranças adotarem IAs para se manterem competitivas</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>17h20</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>luiz gomes</p>
        </div>
      </ul>
    </Tabs.Content>
    <Tabs.Content className="TabsContent" value="tab2">
      <ul className='list-disc pl-5'>
        <li className="uppercase "><strong className='font-black'>Análise de dados para gerar mais resultados</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>9h</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>silvio monte</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Como utilizar inteligência artificial para aumentar vendas e conversões</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>9h50</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>leo medeiros</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>UX Writing em tempos de inteligência artificial</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>10h40</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>bruna sarga</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Como extrair insights pra negócios de dados estruturados</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>11h30</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>Fernando Aureliano e Guilherme Bastos</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Automação de MKT e Chat GPT, unindo forças na captação B2B</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>14h</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>Daniel Gonçalves</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>IA e identificação de oportunidades para ideação de produtos</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>14h50</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>Thais Lopes, Izabella Cavalcanti e Phelipe Menezes</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>IA nas otimizações de anúncios do GOOGLE</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>15h40</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>malu galindo</p>
        </div>
        <br />
        <li className="uppercase "><strong className='font-black'>Direito Autoral e IAs</strong></li>
        <div className='flex items-center'>
          <Icon icon="memory:clock" />
          <p className='font-light uppercase text-sm mx-1.5'>16h30</p>
          <p className='mx-3'> | </p>
          <Icon icon="pixelarticons:user" />
          <p className='font-light uppercase text-sm mx-1.5'>victor macedo</p>
        </div>
      </ul>
    </Tabs.Content>
  </Tabs.Root>
);

export default Tabcard;
