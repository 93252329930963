import React, { useEffect } from 'react';
import { Icon } from '@iconify/react';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';

import './button.css';

import fotoednaldo from '../assets/Ednaldo Junior.jpeg';
import fotobruna from '../assets/Bruna Sarga.jpeg';
import fotojoao from '../assets/joaosalgues.png';
import fotomaira from '../assets/mairafisher.jpg';
import fotobruno from '../assets/bruno.jpg';
import fotoanselmo from '../assets/anselmoalbuquerque.png';
import fotogabrielle from '../assets/gabriellebarros.jpg';
import fotoligia from '../assets/ligia.png';
import fotomalu from '../assets/malugalindo.png';
import fotofernando from '../assets/FernandoAureliano.png';
import fotoluizgomes from '../assets/luizgomes.png';
import fotoanalu from '../assets/analugama.jpeg';
import fotosilvio from '../assets/silvio.jpg';
import fotofelipe from '../assets/Felipe Mozart.jpg';
import fotoleo from '../assets/Leo Medeiros.jpg';
import fotoguilherme from '../assets/Guilhermebastos.jpg';
import fotoizabella from '../assets/izabella.jpeg';
import fotoyvson from '../assets/yvsonlima.jpg';
import fotothais from '../assets/Thais lopes.jpg';
import fotogabi from '../assets/gabidudu.jpg';
import fotopaolo from '../assets/paoloricardo.png';
import fotogabriel from '../assets/gabrielchamie.jpg';
import fotoraphael from '../assets/raphaelvasconcelos.jpg';
import fotocaroliny from '../assets/caroliny.jpg';
import fotocarcara from '../assets/tiagocarcara.jpg';
import fotogeorge from '../assets/georgefilho.jpg';
import fotoigor from '../assets/igorvilaca.png';
import fotofranz from '../assets/franz.jpg';
import fotovictor from '../assets/victormacedo.png';
import fotophelipe from '../assets/phelipemenezes.png';
import fotodanielg from '../assets/danielgoncalves.jpg';
import fotopedrosimoes from '../assets/pedrosimoes.png';


const CarouselMobile = () => {
  useEffect(() => {
    new Glide('.glide', {
      type: 'slider',
      /* autoplay: 0,
      animationTimingFunc: 'linear', */
      animationDuration: 600,
      perView: 1,
      startAt: 0,
      gap: 20,
      peek: 50,
      focusAt: 'center',
      keyboard: true,
    }).mount();
  }, []);

  return (
    <div className="glide relative">
      <div className="glide__arrows my-2 text-center absolute left-0 z-50 top-52" data-glide-el="controls">
        <button className="glide__arrow glide__arrow--prev scale-150" data-glide-dir="<"><Icon icon="material-symbols:arrow-left" width={60} height={60} /></button>
      </div>
      <div className="glide__track pt-8 pb-4 relative" data-glide-el="track">
          <ul className="glide__slides">
            <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotomalu} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>malu galindo</h1>
                  <p className='text-sm/tight font-semibold'>account strategist | google</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotosilvio} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>silvio monte</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>head de mídia e bi | carvalheira</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotofernando} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>fernando aureliano</h1>
                  <p className='text-sm/tight font-semibold'>Business analyst | nubank</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoanalu} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>analu gama</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Account Manager Specialist | safetec/wecolab</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoednaldo} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>edinaldo junior</h1>
                  <p className='text-sm/tight font-semibold'>data scientist | pic pay</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotobruna} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>bruna sarga</h1>
                  <p className='text-sm/tight font-semibold'>Content Designer | Itaú Unibanco</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotojoao} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>joão salgues</h1>
                  <p className='text-sm/tight font-semibold'>Head de projetos | cosmobots</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotomaira} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>maíra fischer</h1>
                  <p className='text-sm/tight font-semibold'>community manager | buupe</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotobruno} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>bruno moura</h1>
                  <p className='text-sm/tight font-semibold'>head growth & data analytics | f5</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotogabrielle} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>gabrielle barros</h1>
                  <p className='text-sm/tight font-semibold'>cmo | kade pay</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoanselmo} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>anselmo albuquerque</h1>
                  <p className='text-sm/tight font-semibold'>co-founder | jogga digital</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoligia} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>ligia sarmento</h1>
                  <p className='text-sm/tight font-semibold'>technical writer | zenvia</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoluizgomes} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none mb-2'>luiz gomes</h1>
                  <p className='text-sm/tight font-semibold'>criador e pesquisador | kamelo</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotofelipe} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>felipe mozart</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Products Learning & Developer Experience Tech Writer | Oiti</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoleo} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>leo medeiros</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Business Strategy Manager {/* | Mesa  Mobile Thinking */}</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoguilherme} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>guilherme bastos</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Founder & CEO | Ray Consulting</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoizabella} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>izabella cavalcanti</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Product Manager | Cesar School</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoyvson} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>yvson lima</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Growth marketing | Educbank</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotothais} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>thais lopes</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Interaction Designer | cesar</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotogabi} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>gabi dudu</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>ceo | Creators Match</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotopaolo} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>paolo ricardo</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>coo | Creators Match</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotogabriel} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>gabriel chamie</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>cto | grite soluções empresariais</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoraphael} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>raphael vasconcelos</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>executivo de contas | agência lean</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotofranz} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>franz lima</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>gerente de negócios e inovação | keek inteligência analítica</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotocaroliny} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>caroliny rodrigues</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>cientista de dados | sidi</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotocarcara} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>tiago carcará</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>diretor de arte | agência b results</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotogeorge} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>george filho</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>co-founder | render criativo</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotoigor} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>igor vilaça</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Content producer | Faculdade Alpha</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotovictor} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>victor macedo</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>advogado | como registrar</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotophelipe} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>Phelipe Menezes</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Head de Social Intelligence | Ampla Comunicação</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotodanielg} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>Daniel gonçalves</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>CEO / GERENTE COMERCIAL | NUVOGRUPO</p>
                </div>
              </li>
              <li className="glide__slide bg-gradient-to-r from-roxoazul to-rosalilas py-3 shadow-md uppercase font-extrabold text-center">
                <img src={fotopedrosimoes} alt='foto da pessoa palestrante' />
                <div className='bg-gradient-to-r from-roxoazul to-rosalilas py-3 px-1 flex flex-col items-center justify-center'>
                  <h1 className='text-2xl/none max-lg:text-lg/none mb-2'>pedro simões</h1>
                  <p className='text-sm/tight font-semibold max-lg:text-xs/none'>Analista de Mídia e Performance<br />Avesso Comunicação</p>
                </div>
              </li>
          </ul>

          <div className="glide__arrows my-2 text-center absolute right-0 z-50 top-52" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--next scale-150" data-glide-dir=">"><Icon icon="material-symbols:arrow-right" width={60} height={60} /></button>
          </div>
      </div>
      <div className="glide">
          <div className="glide__bullets flex justify-center" data-glide-el="controls[nav]">
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=0"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=1"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=2"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=3"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=4"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=5"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=6"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=7"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=8"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=9"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=10"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=11"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=12"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=13"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=14"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=15"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=16"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=17"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=18"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=19"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=20"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=21"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=22"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=23"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=24"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=25"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=26"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=27"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=28"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=29"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=30"></button>
              <button className="glide__bullet p-1 bg-white/60 m-0.5 rounded-full hover:bg-white cursor-pointer" data-glide-dir="=31"></button>
          </div>
      </div>
    </div>
  );
};

export default CarouselMobile;
