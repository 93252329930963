/* eslint-disable no-undef */
import React from 'react';
import { Icon } from '@iconify/react';
// import { HashLink } from 'react-router-hash-link';
import Header from '../components/Header';
import Footer from '../components/Footer';

import iconeraio from '../assets/iconeraio.png';

function Programacao() {

  return (
    <>
      <Header />
      {/* <section id="intro" className='text-center my-10'>
        <div className='md:flex md:items-center md:justify-center md:mx-20'>
          <div className='w-3/4 mx-auto flex justify-center md:justify-end my-5 md:h-32 md:w-96 md:mx-5'>
            <img src={logo} alt="logo imersão mkt do futuro" className='w-3/4 object-contain' />
          </div>
          <h2 className='mx-10 my-8 uppercase font-bold text-xl/tight md:text-2xl/none tracking-tight text-center md:text-left md:w-60 md:mx-5'>Dados e IA reconfigurando<br /> o jeito de fazer marketing</h2>
        </div>
        <div className='flex uppercase items-center justify-center mx-10 my-10'>
          <h5 className='text-xs font-bold mx-2'>realização:</h5>
          <div className=''>
            <img src={logobd} alt="logo berlim digital" className='object-scale-down md:w-48' />
          </div>
        </div>
        <div className='relative mx-auto w-fit my-8'>
            <h2 className='text-3xl text-center uppercase border-4 border-black py-2 px-4 w-full font-bold'>últimos momentos para garantir sua inscrição!</h2>
        </div>
        <HashLink smooth to='/#planos'>
          <button
            type='button'
            className='bg-black text-white my-2 mx-5 text-2xl uppercase font-black w-fit cursor-pointer hover:text-3xl transition-all ease-in-out diagonal2'
          >
            garanta sua vaga
          </button>
        </HashLink>
      </section> */}

      <section id='title' className='flex items-center justify-center my-5 mx-10 lg:mx-48 xl:mx-72'>
        <div className='bg-midnight w-fit p-5 flex items-center' >
          <div className='w-5 sm:w-12 mr-3'>
            <img src={iconeraio} alt='icone de um raio em pixel art' className='object-contain' />
          </div>
          <h1 className='uppercase text-white text-2xl sm:text-5xl font-bold'>programação</h1>
          <div className='w-5 sm:w-12 ml-3'>
            <img src={iconeraio} alt='icone de um raio em pixel art' className='object-contain' />
          </div>
        </div>
      </section>

      <section id='tagline' className='mx-10 lg:mx-48 xl:mx-72 mb-5'>
        <div className='w-full flex justify-center'>
          <div className=' text-midnight p-4 uppercase flex items-center text-left'>
            <div className='w-full sm:flex justify-evenly text-lg sm:text-xl whitespace-nowrap'>
              <div className='my-2 flex-1 mx-3 max-sm:flex max-sm:justify-between'>
                <p><strong>credenciamento</strong></p>
                <p className='flex items-center max-sm:ml-4'><Icon icon='memory:clock' className='mr-2' /> 8h</p>
              </div>
              <div className='bg-midnight w-0.5 my-3 mx-3 max-sm:hidden'></div>
              <div className='my-2 flex-1 mx-3 max-sm:flex max-sm:justify-between'>
                <p><strong>início talks</strong></p>
                <p className='flex items-center max-sm:ml-4'><Icon icon='memory:clock' className='mr-2' /> 9h</p>
              </div>
              <div className='bg-midnight w-0.5 my-3 mx-3 max-sm:hidden'></div>
              <div className='my-2 flex-1 mx-3 max-sm:flex max-sm:justify-between'>
                <p><strong>término evento</strong></p>
                <p className='flex items-center max-sm:ml-4'><Icon icon='memory:clock' className='mr-2' /> 18h</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='palco-tomorrow' className='flex justify-center mb-5'>
        <div className='bg-midnight pt-4 pb-8 text-white w-[300px] sm:w-[580px] xl:w-[720px]'>
          <h2 className='uppercase text-5xl text-center pt-5 pb-3 font-black'>palco tomorrow</h2>
          <div className='bg-white h-px w-2/3 mx-auto'></div>
          <ul className='list-disc pl-5 mx-5'>
            <h3 className='uppercase text-3xl text-center py-5 font-bold'>sábado</h3>
            <li className="uppercase "><strong className='font-black'>Potencialize sua produtividade com IA</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>anselmo albuquerque</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Relações sociais e gestão de comunidade em Tempos de AI</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h50</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>maíra fischer</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Chat GPT e WhatsApp: O que podemos esperar dessa relação?</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>10h40</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>joão miguel salgues</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Technical Writing e AI: Não é mais uma página em branco</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>11h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>bruna sarga, ligia sarmento e felipe mozart</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>como o google pode potencializar o seu negócio usando IA</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>analu gama</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>IA Generativa: Muito Além do Chat GPT e Bard</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h50</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>bruno moura</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>GA4 desafios e como usar seu potencial</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>15h40</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>gabrielle araújo</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Como extrair insights de dados para melhorar produtos e comunicação</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>16h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>edinaldo junior</p>
            </div>
            <br />
            <li className="uppercase ">17:20 <strong className='font-black'>a importância das lideranças adotarem IAs para se manterem competitivas</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>17h20</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>luiz gomes</p>
            </div>
          </ul>
          <ul className='list-disc pl-5 mx-5'>
            <h3 className='uppercase text-3xl text-center py-5 font-bold'>domingo</h3>
            <li className="uppercase "><strong className='font-black'>Análise de dados para gerar mais resultados</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>silvio monte</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Como utilizar inteligência artificial para aumentar vendas e conversões</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h50</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>leo medeiros</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>UX Writing em tempos de inteligência artificial</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>10h40</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>bruna sarga</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Como extrair insights pra negócios de dados estruturados</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>11h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>Fernando Aureliano e Guilherme Bastos</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Automação de MKT e Chat GPT, unindo forças na captação B2B</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>Daniel Gonçalves</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>IA e identificação de oportunidades para ideação de produtos</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h50</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>Thais Lopes, Izabella Cavalcanti e Phelipe Menezes</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>IA nas otimizações de anúncios do GOOGLE</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>15h40</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>malu galindo</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Direito Autoral e IAs</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>16h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>victor macedo</p>
            </div>
          </ul>
        </div>
      </section>

      <section id='arena-creator' className='flex justify-center mb-5'>
        <div className='bg-midnight pt-4 pb-8 text-white w-[300px] sm:w-[580px] xl:w-[720px]'>
          <h2 className='uppercase text-5xl text-center pt-5 pb-3 font-black'>arena creator</h2>
          <div className='bg-white h-px w-2/3 mx-auto'></div>
          <ul className='list-disc pl-5 mx-5'>
            <h3 className='uppercase text-3xl text-center py-5 font-bold'>sábado</h3>
            <li className="uppercase "><strong className='font-black'>Otimização de anúncios utilizando o Bard e outras ferramentas</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>pedro simões</p>
            </div>
            <br />

            <li className="uppercase "><strong className='font-black'>como planejar um lançamento de produto com ia</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>izabella cavalcanti</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>edição de vídeos com ia</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>igor vilaça</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>Iniciando com chat GPT: Dicas e boas práticas em Copy</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>15h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>ligia sarmento</p>
            </div>
            <br />

            <li className="uppercase "><strong className='font-black'>motion e animações com ia</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>17h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>george filho</p>
            </div>
          </ul>
          <ul className='list-disc pl-5 mx-5'>
            <h3 className='uppercase text-3xl text-center py-5 font-bold'>domingo</h3>
            <li className="uppercase "><strong className='font-black'>midjourney e photoshop na criação de conteúdo</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>tiago carcará</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>engenharia de prompts para obter melhores resultados</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>bruno moura</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>data analytics para influência digital</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>paolo e gabi dudu</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>ia generativa e llm na produção de copy</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>15:30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>daniel gonçalves</p>
            </div>
          </ul>

        </div>
      </section>

      <section id='arena-business' className='flex justify-center mb-10'>
        <div className='bg-midnight pt-4 pb-8 text-white w-[300px] sm:w-[580px] xl:w-[720px]'>
          <h2 className='uppercase text-5xl text-center pt-5 pb-3 font-black'>arena business</h2>
          <div className='bg-white h-px w-2/3 mx-auto'></div>
          <ul className='list-disc pl-5 mx-5'>
            <h3 className='uppercase text-3xl text-center py-5 font-bold'>sábado</h3>
            <li className="uppercase "><strong className='font-black'>get prospect e chat gpt: como captar leads e criar fluxos comerciais</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>raphael vasconcelos</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>nocode e ia: como criar sistemas e acelerar inovação sem precisar criar código</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>gabriel chamie</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>ia e automação com zapier</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>joão salgues</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>data intelligence: como extrair insights de dados brutos para tomada de decisão</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>15:30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>franz lima</p>
            </div>
          </ul>
          <ul className='list-disc pl-5 mx-5'>
            <h3 className='uppercase text-3xl text-center py-5 font-bold'>domingo</h3>
            <li className="uppercase "><strong className='font-black'>criando dashboards de dados para acompanhar resultados de vendas</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>9h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>caroliny rodrigues</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>ia economy: identificação de oportunidades de négocios e verticais de crescimento</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>yvson lima</p>
            </div>
            <br />
            <li className="uppercase "><strong className='font-black'>mercado adulto e ia: uma janela de oportunidades</strong></li>
            <div className='flex items-center'>
              <Icon icon="memory:clock" />
              <p className='font-light uppercase text-sm mx-1.5'>14h</p>
              <p className='mx-3'> | </p>
              <Icon icon="pixelarticons:user" />
              <p className='font-light uppercase text-sm mx-1.5'>maíra fischer</p>
            </div>
          </ul>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Programacao;

