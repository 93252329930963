import React from 'react';
import { Icon } from '@iconify/react';
import * as Tabs from '@radix-ui/react-tabs';
import './styles.css';

const Tabcard2 = () => (
  <Tabs.Root className="TabsRoot" defaultValue="tab1">
    <Tabs.List className="TabsList" aria-label="Manage your account">
      <Tabs.Trigger className="TabsTrigger text-sm/none font-extrabold uppercase" value="tab1">
        arena creator
      </Tabs.Trigger>
      <Tabs.Trigger className="TabsTrigger text-sm/none font-extrabold uppercase" value="tab2">
        arena business
      </Tabs.Trigger>
    </Tabs.List>
    <Tabs.Content className="TabsContent" value="tab1">
      <div className='my-4'>
        <h2 className='uppercase font-black text-xl mb-3'>sábado</h2>
        <ul className='list-disc pl-5'>
          <li className="uppercase "><strong className='font-black'>Otimização de anúncios utilizando o Bard e outras ferramentas</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>9h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>pedro simões</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>como planejar um lançamento de produto com ia</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>izabella cavalcanti</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>edição de vídeos com ia</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>14h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>igor vilaça</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>Iniciando com chat GPT: Dicas e boas práticas em Copy</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>15h30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>ligia sarmento</p>
          </div>
          <br />
          
          <li className="uppercase "><strong className='font-black'>motion e animações com ia</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>17h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>george filho</p>
          </div>
        </ul>
      </div>

      <div className='my-4'>
        <h2 className='uppercase font-black text-xl mb-3'>domingo</h2>
        <ul className='list-disc pl-5'>
          <li className="uppercase "><strong className='font-black'>midjourney e photoshop na criação de conteúdo</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>9h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>tiago carcará</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>engenharia de prompts para obter melhores resultados</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>bruno moura</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>data analytics para influência digital</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>14h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>paolo e gabi dudu</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>ia generativa e llm na produção de copy</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>15:30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>daniel gonçalves</p>
          </div>
        </ul>
      </div>

    </Tabs.Content>
    <Tabs.Content className="TabsContent" value="tab2">
      <div className='my-4'>
        <h2 className='uppercase font-black text-xl'>sábado</h2>
        <ul className='list-disc pl-5'>
          <li className="uppercase "><strong className='font-black'>get prospect e chat gpt: como captar leads e criar fluxos comerciais</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>9h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>raphael vasconcelos</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>nocode e ia: como criar sistemas e acelerar inovação sem precisar criar código</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>gabriel chamie</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>ia e automação com zapier</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>14h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>joão salgues</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>data intelligence: como extrair insights de dados brutos para tomada de decisão</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>15:30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>franz lima</p>
          </div>
        </ul>
      </div>
      <div className='my-4'>
        <h2 className='uppercase font-black text-xl'>domingo</h2>
        <ul className='list-disc pl-5'>
          <li className="uppercase "><strong className='font-black'>criando dashboards de dados para acompanhar resultados de vendas</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>9h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>caroliny rodrigues</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>ia economy: identificação de oportunidades de négocios e verticais de crescimento</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>10h30</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>yvson lima</p>
          </div>
          <br />
          <li className="uppercase "><strong className='font-black'>mercado adulto e ia: uma janela de oportunidades</strong></li>
          <div className='flex items-center'>
            <Icon icon="memory:clock" />
            <p className='font-light uppercase text-sm mx-1.5'>14h</p>
            <p className='mx-3'> | </p>
            <Icon icon="pixelarticons:user" />
            <p className='font-light uppercase text-sm mx-1.5'>maíra fischer</p>
          </div>
        </ul>
      </div>
    </Tabs.Content>
  </Tabs.Root>
);

export default Tabcard2;
