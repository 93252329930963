import React from 'react';
import './button.css';
// import { Link } from 'react-router-dom';

const Prices = () => {
  const dayFeatures = [
        'Acesso ao evento 1 dia',
        'Acesso ao palco principal',
        'Acesso à área de expositores',
        'Acesso ao After Party',
        'Acesso às arenas',
        'Certificado de participação',
        'Desconto de 20% na Berlim Plus',
      ];
  const allFeatures = [
        'Acesso ao evento 2 dias',
        'Acesso ao palco principal',
        'Acesso à área de expositores',
        'Acesso às arenas',
        'Acesso ao After Party',
        'Certificado de participação',
        'Kit boas vindas',
        '3 meses de acesso à Berlim Plus',
        'crachá personalizado'
      ]

  return (
    <div className="text-black relative flex justify-evenly">
        <div className="bg-white px-5 pb-10 pt-5 flex flex-col justify-center items-center mx-2 lg:mx-5">
          <h2 className='text-3xl font-black uppercase'>day 1 pass</h2>
          <p className='uppercase font-semibold text-lg'>(sábado)</p>
          <div className='flex items-center justify-center'>
            <div className='flex flex-col uppercase text-3xl/none mx-0'>
              <p className='text-base font-bold'>até</p>
              <p>10x</p>
              <p>R$</p>
              <p className='text-xs tracking-tighter'>sem juros</p>
            </div>
            <h1 className='text-8xl/tight uppercase font-black'>35</h1>
            <p className='text-4xl/tight uppercase font-semibold'>,30</p>
          </div>
          <ul className="text-center uppercase text-sm tracking-tight flex flex-col items-center justify-center my-5">
            {dayFeatures.map((feature, index) => (
              <li key={index} className='border border-black py-0.5 px-1 my-1 text-center w-fit'>{feature}</li>
            ))}
          </ul>
          <div className='absolute -bottom-8'>
            <a href=" https://www.sympla.com.br/imersao-mkt-do-amanha__2031292">
              <button
                type='button'
                className={`bg-midnight text-white transition-all ease-in-out my-2 text-3xl hover:text-4xl uppercase font-black py-2 px-4 w-fit cursor-pointer diagonal whitespace-nowrap`}
              >
                eu quero!
              </button>
            </a>
          </div>
        </div>
        <div className="bg-midnight text-white px-5 pb-10 pt-5 flex flex-col justify-center items-center mx-2 lg:mx-5">
          <h2 className='text-3xl font-black uppercase'>all access</h2>
          <div className='flex items-center justify-center'>
            <div className='flex flex-col uppercase text-3xl/none mx-0'>
              <p className='text-base font-bold'>até</p>
              <p>10x</p>
              <p>R$</p>
              <p className='text-xs tracking-tighter'>sem juros</p>
            </div>
            <h1 className='text-8xl/tight uppercase font-black'>54</h1>
            <p className='text-4xl/tight uppercase font-semibold'>,50</p>
          </div>
          <ul className="text-center uppercase text-sm tracking-tight flex flex-col items-center justify-center my-5">
            {allFeatures.map((feature, index) => (
              <li key={index} className='border border-white py-0.5 px-1 my-1 text-center w-fit'>{feature}</li>
            ))}
          </ul>
          <div className='absolute -bottom-8'>
            <a href=" https://www.sympla.com.br/imersao-mkt-do-amanha__2031292">
              <button
                type='button'
                className={`bg-white text-midnight transition-all ease-in-out my-2 text-3xl hover:text-4xl uppercase font-black py-2 px-4 w-fit cursor-pointer diagonal whitespace-nowrap`}
              >
                eu quero!
              </button>
            </a>
          </div>
        </div>
        <div className="bg-white px-5 pb-10 pt-5 flex flex-col justify-center items-center mx-2 lg:mx-5">
          <h2 className='text-3xl font-black uppercase'>day 2 pass</h2>
          <p className='uppercase font-semibold text-lg'>(domingo)</p>
          <div className='flex items-center justify-center'>
            <div className='flex flex-col uppercase text-3xl/none mx-0'>
              <p className='text-base font-bold'>até</p>
              <p>10x</p>
              <p>R$</p>
              <p className='text-xs tracking-tighter'>sem juros</p>
            </div>
            <h1 className='text-8xl/tight uppercase font-black'>35</h1>
            <p className='text-4xl/tight uppercase font-semibold'>,30</p>
          </div>
          <ul className="text-center uppercase text-sm tracking-tight flex flex-col items-center justify-center my-5">
            {dayFeatures.map((feature, index) => (
              <li key={index} className='border border-black py-0.5 px-1 my-1 text-center w-fit'>{feature}</li>
            ))}
          </ul>
          <div className='absolute -bottom-8'>
            <a href=" https://www.sympla.com.br/imersao-mkt-do-amanha__2031292">
              <button
                type='button'
                className={`bg-midnight text-white transition-all ease-in-out my-2 text-3xl hover:text-4xl uppercase font-black py-2 px-4 w-fit cursor-pointer diagonal whitespace-nowrap`}
              >
                eu quero!
              </button>
            </a>
          </div>
        </div>
    </div>
  );
};

export default Prices;
