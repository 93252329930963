import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import './button.css';
// import { Link } from 'react-router-dom';

const CarouselPrices = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const plans = [
    {
      title: 'all access',
      price: '54',
      cents: ',50',
      times: 10,
      features: [
        'Acesso ao evento 2 dias',
        'Acesso ao palco principal',
        'Acesso à área de expositores',
        'Acesso às arenas',
        'Acesso ao After Party',
        'Certificado de participação',
        'Kit boas vindas',
        '3 meses de acesso à Berlim Plus',
        'crachá personalizado'
      ],
      specialCard: true,
    },
    {
      title: 'day 1 pass',
      dayof: '(sábado)',
      price: '35',
      cents: ',30',
      times: 10,
      features: [
        'Acesso ao evento 1 dia',
        'Acesso ao palco principal',
        'Acesso à área de expositores',
        'Acesso ao After Party',
        'Acesso às arenas',
        'Certificado de participação',
        'Desconto de 20% na Berlim Plus',
      ],
    },
    {
      title: 'day 2 pass',
      dayof: '(domingo)',
      price: '35',
      cents: ',30',
      times: 10,
      features: [
        'Acesso ao evento 1 dia',
        'Acesso ao palco principal',
        'Acesso à área de expositores',
        'Acesso ao After Party',
        'Acesso às arenas',
        'Certificado de participação',
        'Desconto de 20% na Berlim Plus',
      ],
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === plans.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? plans.length - 1 : prevSlide - 1));
  };

  const currentPlan = plans[currentSlide];

  return (
    <div className="carousel text-white relative">
      <button className={`carousel__arrow carousel__arrow--prev absolute top-40 -left-8 cursor-pointer ${currentPlan.specialCard ? 'text-midnight' : 'text-white'
        }`} onClick={prevSlide}>
        <Icon icon="material-symbols:arrow-left" width={60} height={60} className='scale-150' />
      </button>
      <div className={`carousel__slide ${currentPlan.specialCard ? 'bg-special-color text-special-text-color' : 'bg-midnight'
        } px-5 pb-10 pt-5 flex flex-col justify-center items-center`}>
        <h2 className='text-3xl font-black uppercase'>{currentPlan.title}</h2>
        <p className={`uppercase font-semibold text-lg ${currentPlan.specialCard ? 'hidden' : ''
        }`}>{currentPlan.dayof}</p>
        <div className='flex my-2 items-center justify-center'>
          <div className='flex flex-col uppercase text-3xl/none mx-0'>
            <p className='text-base font-bold'>até</p>
            <p>{currentPlan.times}x</p>
            <p>R$</p>
            <p className='text-xs tracking-tighter'>sem juros</p>
          </div>
          <h1 className='text-8xl/tight uppercase font-black'>{currentPlan.price}</h1>
          <p className='text-4xl/tight uppercase font-semibold'>{currentPlan.cents}</p>
        </div>
        <ul className={`text-center uppercase text-sm tracking-tight flex flex-col items-center justify-center my-5 ${currentPlan.specialCard ? 'special-card' : ''}`}>
          {currentPlan.features.map((feature, index) => (
            <li
              key={index}
              className={`border border-white py-0.5 px-1 my-1 text-center w-fit ${currentPlan.specialCard ? 'bg-special-color text-special-text-color' : ''
                }`}
            >
              {feature}
            </li>
          ))}
        </ul>
        <div className='absolute -bottom-8'>
          <a href=" https://www.sympla.com.br/imersao-mkt-do-amanha__2031292">
            <button
              type='button'
              className={` transition-all ease-in-out my-2 text-3xl hover:text-4xl uppercase font-black py-2 px-4 w-fit cursor-pointer diagonal whitespace-nowrap ${
                currentPlan.specialCard ? 'bg-midnight text-white' : 'bg-white text-midnight'
              }`}
            >
              eu quero!
            </button>
          </a>
        </div>
      </div>
      <button className={`carousel__arrow carousel__arrow--prev absolute top-40 -right-8 cursor-pointer ${currentPlan.specialCard ? 'text-midnight' : 'text-white'
        }`} onClick={nextSlide}>
        <Icon icon="material-symbols:arrow-right" width={60} height={60} className='scale-150' />
      </button>
    </div>
  );
};

export default CarouselPrices;
