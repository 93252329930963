import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
// import classNames from 'classnames';
import { Icon } from '@iconify/react';
import './accordion.css';

const AccordionFAQ = () => (
  <Accordion.Root className="AccordionRoot" type="single" /* defaultValue="item-1"  */collapsible>
    <Accordion.Item className="AccordionItem" value="item-1">
      <AccordionTrigger>como se inscrever?</AccordionTrigger>
      <AccordionContent>
        <div className="AccordionContentText">
          As compras serão realizadas através do botão EU QUERO, você será redirecionado para a plataforma de pagamento Sympla, onde poderá escolher o plano desejado.
        </div>
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className="AccordionItem" value="item-2">
      <AccordionTrigger>onde acontecerá<br className='sm:hidden' /> o evento?</AccordionTrigger>
      <AccordionContent>
        <div className="AccordionContentText">
          O evento será realizado no Centro Cultural Cais do Sertão Av. Alfredo Lisboa, s/n - Recife, PE, 50030-150
        </div>
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className="AccordionItem" value="item-3">
      <AccordionTrigger>sou aluno berlim,<br className='sm:hidden' /> tenho desconto?</AccordionTrigger>
      <Accordion.Content className="AccordionContent">
        <div className="AccordionContentText">
          Claro, todos os nossos Berlins tem desconto! É só entrar em contato com nossa central de atendimento no número (81) 98202-2720, informar seu nome completo e e-mail, e resgatar seu desconto!
        </div>
      </Accordion.Content>
    </Accordion.Item>

    <Accordion.Item className="AccordionItem" value="item-4">
      <AccordionTrigger>terá certificado?</AccordionTrigger>
      <Accordion.Content className="AccordionContent">
        <div className="AccordionContentText">
          Sim, os participantes receberão certificados no e-mail cadastrado no ato da compra, até 3 dias após o evento.
        </div>
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Root>
);

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="AccordionHeader">
    <Accordion.Trigger
      className='AccordionTrigger'
      {...props}
      ref={forwardedRef}
    >
      {children}
      <Icon icon="teenyicons:down-solid" className="AccordionChevron" aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className='AccordionContent'
    {...props}
    ref={forwardedRef}
  >
    <div className="AccordionContentText">{children}</div>
  </Accordion.Content>
));

export default AccordionFAQ;
