/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { HashLink } from 'react-router-hash-link';
import Header from './components/Header';
import Footer from './components/Footer';
import Tabcard from './components/Tabcard';
import Tabcard2 from './components/Tabcard2';
import Carousel from './components/Carousel';
import CarouselPrices from './components/CarouselPrices';
import AccordionFAQ from './components/Accordion';
import MapEmbed from './components/MapEmbed';
import Prices from './components/Prices';
import CarouselMobile from './components/CarouselMobile';
import MapMobile from './components/MapMobile';

import './components/button.css';

import galaxy from './assets/bg-galaxy.jpg';
import astro from './assets/pngegg (1).png';
import planeta from './assets/Planet-PNG-Photo-Image.png';
import iconeraio from './assets/iconeraio.png';
import logo from './assets/MktDoFuturo_IDVisual-07.png';
import logobd from './assets/Logo-BerlimDigital.png';
import fotocds from './assets/gif-site-mobile.gif';
import fotocrt from './assets/fotocrt.png';
import logoverdab from './assets/logo-verda-branca-03.png';

import logoPBB from './assets/Regua-01.png';
import logobr from './assets/Regua-02.png';
import logoplus from './assets/Regua-03.png';
import logoesporte from './assets/Regua-04.png';
import logoonabet from './assets/Regua-05.png';
import logoconnect from './assets/Regua-06.png';
import logogw from './assets/Regua-07.png';
import logoallticket from './assets/Regua-08.png';
import logoponte from './assets/Regua-09.png';
import logomitpay from './assets/Regua-10.png';
import logoPega from './assets/Regua-11.png';
import logostation from './assets/Regua-12.png';
// import logocesar from './assets/Regua-13.png';
import logocomoreg from './assets/Regua-14.png';
import logotake from './assets/Regua-15.png';
import logoverda from './assets/Regua-16.png';
import logonovaimp from './assets/Regua-17.png';
import logomxm from './assets/Regua-18.png';
import logoilab from './assets/Regua-19.png';
import logocrt from './assets/Regua-20.png';
import logovivimelo from './assets/Regua-21.png';
import logoporchetta from './assets/Regua-22.png';
import logoacaise from './assets/Regua-23.png';
import logoernest from './assets/Regua-24.png';
import logobloom from './assets/Regua-25.png';
import logomeloev from './assets/Regua-26.png';
import logosetur from './assets/Regua-27.png';
import logoempetur from './assets/Regua-28.png';
import logoseturpe from './assets/Regua-29.png';
import logosebrae from './assets/Regua-30.png';
import logoinstcegos from './assets/Regua-31.png';
// import logogrupo from './assets/logo-grupo.png';
// import logoticket from './assets/TicketResults.png';
// import logowecolab from './assets/wecolablogo.png';

const initialSponsor = {
  nome: '',
  emailCorp: '',
  wpp: '',
  cargo: '',
  empresa: '',
  website: '',
};
const initialUser = {
  nome: '',
  email: '',
  wpp: '',
  assunto: '',
  descricao: '',
};

function App() {
  const screenSize = window.innerWidth;
  const [formSponsor, setFormSponsor] = useState(initialSponsor);
  const [formUser, setFormUser] = useState(initialUser);
  const [mapOpen, setMapOpen] = useState(false);

  const handleSponsor = ({ target }) => {
    const { name, value } = target;
    setFormSponsor((prevFormSponsor) => ({
      ...prevFormSponsor,
      [name]: value,
    }));
  };

  const handleUser = ({ target }) => {
    const { name, value } = target;
    setFormUser((prevFormUser) => ({
      ...prevFormUser,
      [name]: value,
    }));
  };

  const emailSponsorBody = `Email enviado do formulário de contato para patrocinadores e expositores no site Imersão MKT do Amanhã. 
    Nome: ${formSponsor.nome};
    Email Corporativo: ${formSponsor.emailCorp};
    Whatsapp: ${formSponsor.wpp}
    Cargo: ${formSponsor.cargo}
    Empresa: ${formSponsor.empresa}
    Website: ${formSponsor.website}`

  const emailUserBody = `Email enviado do formulário de contato para dúvidas no site Imersão MKT do Amanhã. 
    Nome: ${formUser.nome};
    Email: ${formUser.email};
    Whatsapp: ${formUser.wpp}
    Assunto: ${formUser.assunto}
    Descrição: ${formUser.descricao}`

  const handleSubmitUser = (e) => {
    e.preventDefault()
    // console.log('entramos na função')
    Email.send({
      Host: "smtp.elasticemail.com",
      Username: "dev@bresults.com.br",
      Password: "A09D016E2451501D02249FF2DF708BCBA878",
      To: "producao@imersaomktdoamanha.com.br",
      From: "dev@bresults.com.br",
      Subject: "Contato Dúvidas",
      Body: emailUserBody,
    }).then(
      message => window.alert('seu email foi enviado, retornaremos o contato em breve', message)
    );
    setFormUser(initialUser);
  };

  const handleSubmitSponsor = (e) => {
    e.preventDefault()
    // console.log('entramos na função')
    Email.send({
      Host: "smtp.elasticemail.com",
      Username: "dev@bresults.com.br",
      Password: "A09D016E2451501D02249FF2DF708BCBA878",
      To: "producao@imersaomktdoamanha.com.br",
      From: "dev@bresults.com.br",
      Subject: "Contato Patrocinador/Expositor",
      Body: emailSponsorBody,
    }).then(
      message => window.alert('seu email foi enviado, retornaremos o contato em breve', message)
    );
    setFormSponsor(initialSponsor);
  };

  return (
    <div className="App">
      <Header />
      <section id="intro" className='text-center my-10'>
        <div className='md:flex md:items-center md:justify-center md:mx-20'>
          <div className='w-3/4 mx-auto flex justify-center md:justify-end my-5 md:h-32 md:w-96 md:mx-5'>
            <img src={logo} alt="logo imersão mkt do futuro" className='w-3/4 object-contain' />
          </div>
          <h2 className='mx-10 my-8 uppercase font-bold text-xl/tight md:text-2xl/none tracking-tight text-center md:text-left md:w-60 md:mx-5'>Dados e IA reconfigurando<br /> o jeito de fazer marketing</h2>
        </div>
        <div className='flex uppercase items-center justify-center mx-10 my-10'>
          <h5 className='text-xs font-bold mx-2'>realização:</h5>
          <div className=''>
            <img src={logobd} alt="logo berlim digital" className='object-scale-down md:w-48' />
          </div>
        </div>
        {/* <div className='uppercase text-xl font-bold  flex flex-col justify-center items-center my-5 md:flex-row md:my-10'>
          <div className='md:flex'>
            <div className='relative flex items-center my-1 w-full md:mx-3'>
              <Icon icon="raphael:plus" width={32} height={32} className='absolute -left-4 bg-white text-roxoazul' />
              <p className='text-center border-4 border-black py-1 pr-1.5 pl-3 w-full whitespace-nowrap'>40 palestrantes</p>
            </div>
            <div className='relative flex items-center my-y w-full md:mx-3'>
              <Icon icon="raphael:plus" width={32} height={32} className='absolute -left-4 bg-white text-roxoazul' />
              <p className='text-center border-4 border-black py-1 pr-1.5 pl-4 w-full'>40 expositores</p>
            </div>
          </div>
          <div className='relative flex items-center my-1 md:mx-3'>
            <Icon icon="raphael:plus" width={32} height={32} className='absolute -left-4 bg-white text-roxoazul' />
            <p className='text-center border-4 border-black py-1 pr-1.5 pl-4'>2,5k participantes</p>
          </div>
        </div> */}
        <div className='relative mx-auto w-fit my-8'>
          <h2 className='text-3xl text-center uppercase border-4 border-black py-2 px-4 w-full font-bold'>últimos momentos para garantir sua inscrição!</h2>
        </div>
        <HashLink smooth to='/#planos'>
          <button
            type='button'
            className='bg-black text-white my-2 mx-5 text-2xl uppercase font-black w-fit cursor-pointer hover:text-3xl transition-all ease-in-out diagonal2'
          >
            garanta sua vaga
          </button>
        </HashLink>
        {/*  <HashLink to='/#planos'>
          <button
            type='button'
            className='bg-black text-white my-2 mx-5 text-2xl uppercase font-black w-fit cursor-pointer diagonal2'
          >
            garanta sua vaga
          </button>
        </HashLink> */}
      </section>
      <section id="transmissao" className='bg-black h-72 text-white uppercase text-center text-4xl max-sm:text-3xl relative flex flex-col justify-center items-center'>
        <div className='bg-fixed bg-cover bg-center w-full h-full absolute opacity-40' style={{backgroundImage: `url(${galaxy})`}} />

          <div className="absolute -top-10 -left-28 w-1/4 max-sm:hidden">
            <img src={planeta} alt="imagem decorativa" />
          </div>
          <div className="absolute w-1/5 -bottom-14 -right-16 max-sm:hidden">
            <img src={astro} alt="imagem decorativa" />
          </div>
          <h2 className='mb-8 font-black z-50 flex items-center'>transmissão<br className='md:hidden' /> online ao vivo<Icon icon="vaadin:dot-circle" color='red' className='ml-2 animate-pulse' /></h2>
          <a href='https://www.sympla.com.br/evento-online/transmissao-online-imersao-mkt-do-amanha/2081567'>
            <button
              type='button'
              className='bg-white text-black my-2 mx-5 text-2xl uppercase font-black w-fit cursor-pointer hover:text-3xl transition-all ease-in-out diagonal2'
            >
              não perca!
            </button>
          </a>
      </section>
      <section id="local" className='bg-cover bg-center text-white uppercase relative' style={{ backgroundImage: `url(${fotocds})` }}>
        <div className='py-10 px-10 max-[340px]:px-5 opacity-100 z-50 md:flex md:flex-col md:justify-center md:items-center'>
          <h2 className='my-5 text-left font-extrabold text-4xl/none tracking-wide font-sans lg:text-6xl/none md:text-center md:w-5/6'>No coração do parque tecnológico do Recife</h2>
          <h2 className='text-left text-xl/tight tracking-tight w-5/6 font-base my-10 md:text-center md:text-2xl/tight md:my-5'><strong className='font-extrabold'>Local: </strong> Centro Cultural Cais do Sertão</h2>
          <div className='border-3 hover:bg-white hover:text-rosalilas transition-all ease-in-out border-white mt-10 text-center md:mt-3'>
            <button className='p-2 font-extrabold uppercase max-[355px]:text-sm whitespace-nowrap cursor-pointer md:text-3xl' onClick={() => setMapOpen(!mapOpen)}>
              clique para ver no mapa
            </button>
          </div>
        </div>
        <div className={`slide-container ${mapOpen ? 'slide-down' : 'slide-up'} relative text-center px-5 flex justify-center`}>
          {screenSize > 420 ? <MapEmbed /> : <MapMobile />}
        </div>
      </section>
      <section id="palestrantes" className='bg-black py-10 text-white'>
        <h1 className='text-center uppercase font-black text-3xl/tight md:text-6xl/tight'>palestrantes</h1>
        <div>
          {screenSize > 768 ? <Carousel /> : <CarouselMobile />}
        </div>
      </section>
      <section id="trilhas" className='text-midnight my-10 mx-10 md:mx-32'>
        {/* <h2 className='border-3 border-midnight text-xl/tight font-extrabold uppercase text-center my-5 mx-auto md:text-4xl/none md:w-5/6'>Em breve divulgaremos + palestrantes <br /> são mais de 40!</h2> */}
        <h1 className='text-center text-2xl/tight max-[420px]:text-xl/tight whitespace-nowrap mt-10 mb-4 text-black md:text-4xl/tight' style={{ fontWeight: 1000 }}>TRILHAS DE CONTEÚDO</h1>
        <div className='md:mx-auto flex justify-center uppercase text-lg/none max-[380px]:text-base/none text-center font-extrabold md:w-5/6'>
          <div className='flex-1 mr-1 md:mx-2'>
            <div className='border-3 border-midnight flex items-center justify-center my-2 h-16 md:my-4 md:h-20'>
              <h5>dados</h5>
            </div>
            <div className='border-3 border-midnight flex items-center justify-center my-2 h-16 md:my-4 md:h-20'>
              <h5>business</h5>
            </div>
            <div className='border-3 border-midnight flex items-center justify-center my-2 h-16 md:my-4 md:h-20'>
              <h5>community<br /> manager</h5>
            </div>
          </div>
          <div className='flex-1 ml-1 md:mx-2'>
            <div className='border-3 border-midnight flex items-center justify-center my-2 h-16 md:my-4 md:h-20'>
              <h5>automação</h5>
            </div>
            <div className='border-3 border-midnight flex items-center justify-center my-2 h-16 md:my-4 md:h-20'>
              <h5>creators</h5>
            </div>
            <div className='border-3 border-midnight flex items-center justify-center my-2 h-16 md:my-4 md:h-20'>
              <h5>inteligência<br /> artificial</h5>
            </div>
          </div>
        </div>
        <h2 className='my-6 text-xl/tight font-semibold font-sans text-center uppercase md:text-3xl/tight md:mx-auto md:w-5/6'>Talks, workshops, mesa de discussões e muita troca de conhecimento</h2>
      </section>
      <section id="responsabilidade" className='bg-gradient-to-tr from-rosalilas to-roxoazul px-10 pt-5 pb-5 md:px-20'>
        <h1 className='uppercase border-3 border-white text-black text-center font-black p-1 my-5 text-2xl/tight max-[390px]:text-xl/tight md:text-4xl/tight md:border-4' style={{ fontWeight: 1000 }}>responsabilidade social e diversidade</h1>
        <div className='uppercase text-white font-semibold text-base/none flex flex-col my-10 md:text-2xl/none md:w-5/6 md:mx-auto'>
          <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>acessibilidade</span>
          </div>
          <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>Transmissão simultânea em Libras</span>
          </div>
          <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>Apoio a cadeirantes durante o evento</span>
          </div>
          {/* <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>Cardápio inclusivo</span>
          </div> */}
          <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>Abafadores de ruídos para pessoas com autismo</span>
          </div>
          <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>Audiodescrição para cegos</span>
          </div>
          <div className='flex items-center my-2'>
            <div>
              <Icon icon="carbon:star" width={24} height={24} className='mx-2 md:scale-125' />
            </div>
            <span>Contratação de pessoas em vulnerabilidade social para trabalhar no evento</span>
          </div>
        </div>
        <div className='flex justify-around items-center w-full my-5 md:w-1/4 md:mx-auto'>
          <h5 className='text-white uppercase text-sm font-medium'>Partner</h5>
          <div className='flex-1 mx-2'>
            <div className='aspect-w-1 aspect-h-1'>
              <img src={logoponte} alt="Logo da Ponte ESG" className='object-contain h-full w-full' />
            </div>
          </div>
          <div className='flex-1 mx-2'>
            <div className='aspect-w-1 aspect-h-1'>
              <img src={logoverdab} alt="Logo da Verda[+]" className='object-contain h-full w-full' />
            </div>
          </div>
        </div>
      </section>
      <section id="talks" className='mx-10 lg:mx-48 xl:mx-72'>
        <div className='w-full flex justify-center lg:justify-end'>
          <div className='bg-midnight text-white py-6 px-3 sm:pl-10 sm:pr-6 my-10 uppercase flex w-[300px] sm:w-[500px] xl:w-[600px] items-center text-left'>
            <div className='w-32 lg:w-1/4'>
              <img src={iconeraio} alt='icone de um raio em pixel art' className='object-contain' />
            </div>
            <div className='flex-1 text-base sm:text-xl ml-6'>
              <div className='my-2'>
                <p><strong>credenciamento</strong></p>
                <p className='flex items-center'><Icon icon='memory:clock' className='mr-2' /> 8h</p>
              </div>
              <div className='my-2'>
                <p><strong>início talks</strong></p>
                <p className='flex items-center'><Icon icon='memory:clock' className='mr-2' /> 9h</p>
              </div>
              <div className='my-2'>
                <p><strong>término evento</strong></p>
                <p className='flex items-center'><Icon icon='memory:clock' className='mr-2' /> 18h</p>
              </div>
            </div>
          </div>
        </div>

        <div className='lg:flex md:my-10 justify-end items-center'>
          <h1 className='text-3xl/none font-black uppercase tracking-tight text-center mt-12 mb-8 md:mx-10 md:text-4xl/none lg:text-right' style={{ fontWeight: 1000 }}>talks<br /><span className='text-2xl/none tracking-tight'>palco tomorrow</span></h1>
          <div className='flex justify-center'>
            <Tabcard />
          </div>
        </div>
        <div className='lg:flex md:my-10 justify-end items-center'>
          <h1 className='text-3xl/none font-black uppercase tracking-tight text-center mt-12 mb-8 md:mx-10 md:text-4xl/none lg:text-right lg:w-72' style={{ fontWeight: 1000 }}>workshops<br /><span className='text-2xl/none tracking-tight'>arenas</span></h1>
          <div className='flex justify-center'>
            <Tabcard2 />
          </div>
        </div>
        <h2 className='my-10 text-xl/tight font-semibold font-sans text-center uppercase md:text-3xl/tight'>3 palcos simultâneos com talks e workshops nas principais trilhas de conhecimento do evento, com lotação limitada.</h2>
      </section>
      <section id="planos" className='bg-gradient-to-tr from-rosalilas to-roxoazul pt-4 pb-20 px-10 flex flex-col items-center'>
        <div className='bg-midnight uppercase text-white p-2 text-center mt-5 mb-10 sm:w-fit sm:px-5'>
          <h1 className='text-2xl/none font-extrabold mb-2 whitespace-nowrap max-[390px]:text-xl/none sm:text-5xl/none'>garanta sua vaga!</h1>
          <h2 className='text-base/tight max-[390px]:text-sm/none sm:text-xl/tight'>Escolha uma das opções<br /> ingressos disponíveis</h2>
        </div>
        <div className='min-[820px]:hidden'>
          <CarouselPrices />
        </div>
        <div className='max-[820px]:hidden'>
          <Prices />
        </div>
      </section>
      <section id='afterparty'>
        <div className='relative mb-40 sm:mb-20 h-96 bg-cover bg-bottom xl:bg-center flex items-center justify-center' style={{ backgroundImage: `url(${fotocrt})` }}>
          <div className='bg-gradient-to-r from-rosalilas to-roxoazul text-white uppercase font-semibold font-sans text-center text-2xl/tight mx-10 absolute -bottom-32 p-2 sm:bottom-20 sm:text-3xl/none md:w-1/2'>
            <h2 className='font-extrabold text-3xl/tight lg:text-4xl/tight mb-3'>after party</h2>
            <h2>dj, pôr do sol, chopp e networking no restaurante cais rooftop</h2>
          </div>
        </div>
        <div className='mx-8 mb-10 text-center lg:mx-32'>
          <h1 className='uppercase text-2xl/none font-black mb-5 lg:mb-16 md:text-4xl/none xl:text-5xl'>marcas presentes no evento</h1>
          <div className='mx-auto w-20 md:w-40 mb-10'>
            <a href='https://www.berlimdigital.com.br/'>
              <img src={logoPBB} alt="Logo Berlim" className='object-contain' />
            </a>
          </div>
          <div className='flex flex-col justify-center items-center lg:mb-10'>
            <h4 className='uppercase text-2xl font-semibold mb-2 text-center'>//future</h4>
            <div className='flex items-center justify-center py-2 lg:mb-0 lg:justify-start lg:w-4/5'>
              <div className='flex justify-center mx-2 lg:mx-4'>
                <a href='https://bresults.com.br/'>
                  <img src={logobr} alt="Logo da B Results" />
                </a>
              </div>
              <div className='flex justify-center mx-2 lg:mx-4'>
                <a href='https://berlimplus.com.br/'>
                  <img src={logoplus} alt="Logo da Berlim Plus" />
                </a>
              </div>
            </div>
            <div className='flex items-center justify-center mb-10 lg:mb-0 lg:justify-start lg:w-4/5'>
              <div className='flex-1 mx-1.5 lg:mx-5'>
                <a href='https://www.esportesdasorte.com/ptb/bet/main'>
                  <img src={logoesporte} alt="Logo da Esportes da Sorte" />
                </a>
              </div>
              <div className='flex-1 mx-1.5 lg:mx-5'>
                <a href='https://onabet.com/casino'>
                  <img src={logoonabet} alt="Logo da OnaBet" />
                </a>
              </div>
              <div className='flex-1 mx-1.5 lg:mx-5'>
                <a href='https://upconnectfibra.com.br/'>
                  <img src={logoconnect} alt="Logo da Connect Fibra" />
                </a>
              </div>
            </div>
          </div>
          <div className='items-center flex flex-col justify-center mb-2'>
            <h4 className='uppercase text-2xl font-semibold text-center'>//discovery</h4>
            <div className='flex flex-col items-center justify-center w-full md:w-5/6 my-8'>
              <div className='flex items-center mb-2 lg:mx-5 lg:w-3/4'>
                <div className='flex-1 mx-1.5 lg:mx-3'>
                  <a href='https://www.wecolab.com.br/'>
                    <img src={logogw} alt="Logo do Wecolab & Google Workspace" />
                  </a>
                </div>
                <div className='mx-1.5 lg:mx-3 w-20 lg:w-40'>
                  <a href='https://www.allticket.com.br/'>
                    <img src={logoallticket} alt="Logo da All Ticket" className='object-contain' />
                  </a>
                </div>
              </div>

              <div className='flex max-md:flex-col items-center justify-center mb-0 lg:mx-5'>
                <div className='flex items-center mb-0'>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://ponteesg.com.br/'>
                      <img src={logoponte} alt="Logo da Ponte ESG" />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.instagram.com/mitpaytec/'>
                      <img src={logomitpay} alt="Logo do Pega Pega Ingresse" className='p-3 lg:p-5' />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://pegapegaingresse.com.br/inicio'>
                      <img src={logoPega} alt="Logo do Pega Pega Ingresse" className='p-3 lg:p-5' />
                    </a>
                  </div>
                </div>
                <div className='flex items-center mb-0'>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://ofertas.berlimdigital.com.br/stationwork'>
                      <img src={logostation} alt="Logo do Station Work" />
                    </a>
                  </div>
                  {/* <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.cesar.org.br/'>
                      <img src={logocesar} alt="Logo da Ticket Results" className='p-2 md:p-4' />
                    </a>
                  </div> */}
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://comoregistrar.com.br/'>
                      <img src={logocomoreg} alt="Logo do Como Registrar" />
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center max-md:flex-col mb-0 lg:mx-5'>
                <div className='flex items-center mb-0'>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.instagram.com/takeajob_/'>
                      <img src={logotake} alt="Logo do Take a Job" className='p-4' />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.somosverda.com.br/'>
                      <img src={logoverda} alt="Logo da Verda[+]" />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://revendanovaimpressao.com.br/'>
                      <img src={logonovaimp} alt="Logo da Nova Impressão" />
                    </a>
                  </div>
                </div>
                <div className='flex items-center mb-3'>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='http://www.mxmgrafica.com.br/'>
                      <img src={logomxm} alt="Logo da MXM" className='p-2' />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://seuprodutoilab.com/'>
                      <img src={logoilab} alt="Logo da iLab" className='p-2' />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://cais-rooftop.cluvi.co/'>
                      <img src={logocrt} alt="Logo do Cais Rooftop" />
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center max-md:flex-col mb-0 lg:mx-5 lg:w-5/6'>
                <div className='flex items-center mb-3 lg:w-5/6'>
                  <div className='flex-1 mx-1.5'>
                    <a href='http://www.chefvivianemelo.com.br/'>
                      <img src={logovivimelo} alt="Logo Viviane Melo" className='lg:p-4' />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.instagram.com/sanduicheporchetta/'>
                      <img src={logoporchetta} alt="Logo do Porchetta" />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://acaise.com.br/'>
                      <img src={logoacaise} alt="Logo da Açaí-se" className='lg:p-3 p-1' />
                    </a>
                  </div>
                </div>
                <div className='flex items-center mb-0 w-2/3 lg:w-3/4'>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.instagram.com/ernestcafebistro/'>
                      <img src={logoernest} alt="Logo do Ernest" className='lg:p-4' />
                    </a>
                  </div>
                  <div className='flex-1 mx-1.5 lg:mx-5'>
                    <a href='https://www.sigabloom.com.br/'>
                      <img src={logobloom} alt="Logo da Bloom" className='lg:p-4' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='items-center flex flex-col justify-center mb-8'>
            <h4 className='uppercase text-2xl font-semibold text-center mb-4'>//apoio</h4>
            <div className='flex items-center justify-center max-md:flex-col mx-1 lg:mx-5 w-5/6'>
              <div className='flex items-center justify-center'>
                <div className='max-sm:flex-1 mx-2'>
                  <a href='https://sebrae.com.br/sites/PortalSebrae/'>
                    <img src={logosebrae} alt="Logo do Sebrae" className='object-contain h-12 w-40s' />
                  </a>
                </div>
                <div className='max-sm:flex-1 mx-2'>
                  <a href='https://www2.recife.pe.gov.br/pagina/secretaria-de-turismo-e-lazer'>
                    <img src={logoseturpe} alt="Logo Secretaria de Turismo e Lazer" className='object-contain h-12 w-32' />
                  </a>
                </div>
                <div className='max-sm:flex-1 mx-2'>
                  <a href='https://www.empetur.pe.gov.br/'>
                    <img src={logoempetur} alt="Logo da Empetur" className='object-contain h-14 w-24 p-2' />
                  </a>
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <div className='max-sm:flex-1 mx-2'>
                  <a href='http://www.setur.pe.gov.br/web/setur'>
                    <img src={logosetur} alt="Logo da Setur" className='object-contain h-20 w-32' />
                  </a>
                </div>
                <div className='max-sm:flex-1 mx-2'>
                  <a href='https://www.instagram.com/meloeventos/?igshid=MzRlODBiNWFlZA%3D%3D'>
                    <img src={logomeloev} alt="Logo da Melo Eventos" className='object-contain h-14 w-32' />
                  </a>
                </div>
                <div className='max-sm:flex-1 mx-2'>
                  <a href='http://www.santacasarecife.org.br/unidade/instituto-de-cegos-antonio-pessoa-de-queiroz/7'>
                    <img src={logoinstcegos} alt="Logo do Instituto de Cegos" className='object-contain h-20 w-32' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className='flex flex-col items-center justify-center mt-10 mb-5 md:mb-0 bg-black py-10'>
        <h1 className='uppercase font-black text-3xl/tight text-center mb-6 text-white px-2'>dúvidas frequentes</h1>
        <AccordionFAQ />
      </section>
      <section id="contato">
        <form id="sponsorForm" className='mx-5 md:mx-0 mb-5 md:mb-0 bg-gradient-to-tr from-rosalilas to-roxoazul text-black uppercase font-medium flex flex-col pt-3 pb-5 items-center justify-center'>
          <h1 className='text-xl/none sm:text-3xl/none lg:text-4xl/none my-5 sm:mx-5 px-5 text-center font-black text-white'>Quer ser um expositor<br className='sm:hidden' /> ou patrocinador<br /> da imersão mkt do amanhã?</h1>
          <label htmlFor='nome' />
          <input required name='nome' type='text' placeholder='nome' value={formSponsor.nome} onChange={handleSponsor} className='bg-white p-2 mx-4 my-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='emailCorp' />
          <input required name='emailCorp' type='email' placeholder='email corporativo' value={formSponsor.emailCorp} onChange={handleSponsor} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='wpp' />
          <input required name='wpp' type='text' placeholder='whatsapp' value={formSponsor.wpp} onChange={handleSponsor} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='cargo' />
          <input required name='cargo' type='text' placeholder='cargo' value={formSponsor.cargo} onChange={handleSponsor} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='empresa' />
          <input required name='empresa' type='text' placeholder='empresa' value={formSponsor.empresa} onChange={handleSponsor} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='website' />
          <input required name='website' type='text' placeholder='website' value={formSponsor.website} onChange={handleSponsor} className='bg-white p-2 mx-4 mb-5 w-5/6 md:w-4/6 uppercase' />
          <button
            type='submit'
            onClick={handleSubmitSponsor}
            className={`bg-white hover:bg-black text-black hover:text-white transition-all ease-in-out my-2 text-3xl hover:text-4xl uppercase font-black py-2 px-4 w-fit cursor-pointer diagonal whitespace-nowrap`}
          >
            Enviar
          </button>
        </form>
        <form id="userForm" className='mx-5 mb-5 md:mx-0 bg-midnight text-midnight uppercase font-medium flex flex-col pb-5 pt-5 justify-center items-center'>
          <h1 className='text-xl/none sm:text-3xl/none lg:text-5xl/none text-white mx-5 my-5 text-center font-extrabold'>dúvidas sobre a imersão<br className='max-sm:hidden' /> mkt do amanhã?</h1>
          <label htmlFor='nome' />
          <input required name='nome' type='text' placeholder='nome' value={formUser.nome} onChange={handleUser} className='bg-white p-2 mx-4 my-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='email' />
          <input required name='email' type='email' placeholder='email' value={formUser.email} onChange={handleUser} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='wpp' />
          <input required name='wpp' type='text' placeholder='whatsapp' value={formUser.wpp} onChange={handleUser} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='assunto' />
          <input required name='assunto' type='text' placeholder='assunto' value={formUser.assunto} onChange={handleUser} className='bg-white p-2 mx-4 mb-2 w-5/6 md:w-4/6 uppercase' />
          <label htmlFor='descricao' />
          <textarea rows={6} name='descricao' placeholder='descrição' value={formUser.descricao} onChange={handleUser} className='bg-white p-2 mb-5 mx-4 w-5/6 md:w-4/6 uppercase' />
          <button
            type='submit'
            onClick={handleSubmitUser}
            className={`bg-white hover:bg-black text-black hover:text-white transition-all ease-in-out my-2 text-3xl hover:text-4xl uppercase font-black py-2 px-4 w-fit cursor-pointer diagonal whitespace-nowrap`}
          >
            Enviar
          </button>
        </form>
        <div className='flex justify-center items-center my-10 mx-10'>
          <div className='mx-4 border-3 border-black rounded-full p-3 flex items-center justify-center'>
            <a href='https://www.instagram.com/imersaomktdoamanha/'>
              <Icon icon="mdi:instagram" width={40} height={40} />
            </a>
          </div>
          <div className='mx-4 border-3 border-black rounded-full p-3 flex items-center justify-center'>
            <a href='https://www.linkedin.com/company/berlim-digital/?originalSubdomain=br'>
              <Icon icon="akar-icons:linkedin-fill" width={40} height={40} />
            </a>
          </div>
          <div className='mx-4 border-3 border-black rounded-full p-3 flex items-center justify-center'>
            <a href='https://www.berlimdigital.com.br/'>
              <Icon icon="mdi:web" width={40} height={40} />
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
