import React from "react";
import { Icon } from "@iconify/react";


function Footer() {
  return (
    <div className="bg-gray-800 h-8 text-white/70 text-xs tracking-tight py-3 w-full max-sm:px-5 flex items-center justify-center uppercase overflow-clip max-[360px]:flex-wrap max-[360px]:h-fit lg:h-10">
      <a href="https://ogrupoberlim.com.br/" className="flex items-center">
        <h1 className="whitespace-nowrap flex items-center justify-center max-[360px]:flex-wrap">Grupo Berlim <Icon icon="ph:copyright" className="mx-1"/>{/*  copyright */} 2023 | <span className="ml-1">desenvolvido por b results</span></h1>
      </a>
    </div>
  )
}

export default Footer;
